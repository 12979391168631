<template>
  <div class="print">

    <div class="right-wrap">
      <div class="search">
        <div class="qjc-flex">
          <el-input v-model="nowMobile" :placeholder="$t('table.t43')" class="qjc-fts-14" @input="accountInput">
            <div class="search-icon" slot="prefix">
              <i class="el-icon-search"></i>
            </div>
          </el-input>
          <div class="query-date">
            <div class="query-date-label">{{ $t('table.t15') }}</div>
            <el-date-picker v-model="dateValue" type="daterange" value-format="timestamp" range-separator="~"
              :start-placeholder="$t('table.t12')" :end-placeholder="$t('table.t13')" @change="searchDateChange">
            </el-date-picker>
          </div>
        </div>

        <el-button @click="query" class="qjc-fts-16 query-btn">{{ $t('table.t39') }}</el-button>
        <el-button @click="handleRet" class="qjc-fts-16 query-btn">{{ $t('table.t40') }}</el-button>
      </div>

      <el-table :data="user" class="list">

        <template slot="empty">
          <div class="empty-wrap">
            <el-image class="empty-img" :src="require('@/assets/images/empty-download.png')"></el-image>
            <div class="empty-text">No data available</div>
          </div>
        </template>

        <el-table-column prop="id" :label="$t('table.t1')" class-name="id" width="80">
          <template slot-scope="scope">
            {{ scope.row.id ? scope.row.id : '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" :label="$t('table.t2')" class-name="mobile">
          <template slot-scope="scope">
            {{ scope.row.mobile ? scope.row.mobile : '--' }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('table.t3')" class-name="package" width="200">
          <template slot-scope="scope">

            <span v-if="scope.row.type == 'AIZH'">[AI]Smart Health</span>
            <span v-else-if="scope.row.type == 'AIFB'">[AI]Smart Fracture-Risk</span>
            <span v-else-if="scope.row.type == 'AIBG'">[AI]VIP Health Risk Examination</span>
            <span v-else-if="scope.row.type == 'AIMB'">[AI]Postpartum Risk (Mother & Newborn)</span>
            <span v-else-if="scope.row.type == 'M22'">[AI]4-Hypers Analysis</span>
            <span v-else>{{ scope.row.name }}</span>
            <span class="custom_info" v-if="scope.row.custom">
              <el-tooltip :content="customFun(scope.row.custom)" placement="bottom">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </span>

          </template>
        </el-table-column>

        <el-table-column prop="sex" :label="$t('table.t4')" class-name="sex" width="80">
          <template slot-scope="scope">
            {{ scope.row.sex == '男' ? 'male' : (scope.row.sex == '女' ? 'female' : 'uncharted') }}
          </template>
        </el-table-column>
        <el-table-column prop="birth" :label="$t('table.t5')" class-name="birth">

        </el-table-column>
        <el-table-column prop="examdate" :label="$t('table.t6')" class-name="examdate">
          <template slot-scope="scope">
            {{ scope.row.examdate ? scope.row.examdate : '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" :label="$t('table.t15')" class-name="created_at">
          <template slot-scope="scope">
            {{ scope.row.created_at ? scope.row.created_at : '--' }}
          </template>
        </el-table-column>

        <el-table-column class-name="oper" prop="oper" :label="$t('table.t9')">
          <template slot-scope="scope">
            <el-button class="btn-text" @click="lookReport(scope.row.id, scope.row.goods_id, scope.row.type)"
              type="text">{{ $t('table.t41') }}</el-button>
            <el-button class="btn-text" @click="download(scope.row.id, scope.row.goods_id, scope.row.type)"
              type="text">{{ $t('table.t42') }}</el-button>
            <!-- <el-button @click="print(scope.row.id, scope.row.package)">一键打印</el-button> -->
          </template>
        </el-table-column>

      </el-table>


      <!-- 分页 -->
      <el-pagination class="pagination qjc-texta-c" hide-on-single-page :current-page.sync="pager.page"
        :page-count="pager.pagecount" :page-size="pager.pagesize" :total="pager.count" @current-change="pageChange"
        prev-text="last" next-text="next" layout="total, prev, pager, next,jumper">
      </el-pagination>

      <Alertb :hidden="alertShow" :tip-status="tipStatus" :tip-text="tipText" @on-close="closeDialog"
        @on-confirm="confirmDialog" />

      <iframe id="iframe" class="qjc-wid-100 qjc-hei-100" :src="iframeUrl" frameborder="0"></iframe>

      <!-- 未安装打印插件 -->
      <div class="no-print qjc-fixed qjc-hide">
        <div class="qjc-absolute qjc-bg-fff qjc-texta-r">
          <p class="qjc-texta-l">未安装打印插件，请先安装</p>
          <span class="qjc-c-primary">关闭</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mainDataQuery, enterpriseList, api } from '@/utils/axios'
import Alertb from '@/components/alertb';
import { Base64 } from 'js-base64';

export default {
  name: 'mainReportPrint',
  components: {
    Alertb
  },
  data () {
    return {
      searchTag: '',//查询id或手机号
      mainHeight: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 60,
      user: [],// 客户信息

      submiting: false,//正在请求

      pager: {
        count: 0,
        page: 1,// 默认第一页
        pagecount: 0,
        pagesize: 12//默认一页12条数据
      },

      nowMobile: '',// 存储当前已查询企业用户id或手机号

      iframeUrl: '',// 下载报告路径

      companyList: [],
      enpId: '',
      dateValue: '',
      downFile: false,
      value: '0',
      http: api + '/v1/backmain/export/',
      alertShow: false,
      tipStatus: '',
      tipText: '',
      adminId: '',
    }
  },
  mounted () {
    // 获取企业列表
    let adminId = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).issupper : '0'
    this.adminId = adminId
    // 获取企业列表

    this.getList()
  },
  watch: {

  },
  methods: {
    getAllText () {
      const allText = document.body.innerText;
      console.log(allText); // 输出页面上的所有文字内容
    },
    customFun (data) {
      let name = '';
      data.map((item) => {
        if (item == 'base') {
          name = name + '基础体检套餐，'
        }
        if (item == 'mlev') {
          name = name + '增值包-通用健康评分，'
        }
        if (item == 'packageA') {
          name = name + '增值包-同族群PLM对比，'
        }
        if (item == 'packageB') {
          name = name + '增值包-共病风险，'
        }
        if (item == 'packageC') {
          name = name + '增值包-疾病风险远期预测，'
        }
        if (item == 'packageD') {
          name = name + '增值包-健康促进方案，'
        }
        if (item == 'packageFB') {
          name = name + '增值包-骨折风险评估，'
        }
      })

      return name.substring(0, name.length - 1)
    },
    accountInput (val) {
      let codeReg = new RegExp("[A-Za-z0-9]+");
      let len = val.length;
      let str = '';
      for (let i = 0; i < len; i++) {
        if (codeReg.test(val[i])) {
          str += val[i]
        }
      }
      this.nowMobile = str
      this.pager.page = 1
      // this.getList();
    },
    handleEnp (id) {
      this.enpId = id
      this.nowMobile = ''
      this.dateValue = ''
      this.getList()
    },
    handleSelectionChange (val) {
      console.log(val)
    },
    downExcel () {
      if (this.dateValue == '' || this.dateValue[0] / 1000 == this.dateValue[1] / 1000 || this.value == '') {
        this.alertShow = true;
        this.tipStatus = "下载失败";
        this.tipText = "请选择合适的起止日期及信息";
      } else {
        this.$refs.down.click();

      }
    },
    confirmDialog () {
      this.alertShow = false;
    },
    closeDialog () {
      this.alertShow = false;
      //把绑定的弹窗数组 设为false即可关闭弹窗
    },
    searchDateChange (val) {
      this.pager.page = 1
      // this.getList();
    },
    query () {
      this.pager.page = 1
      if (this.nowMobile.trim() == '' && this.dateValue == '') {

      } else {
        this.getList();
      }
    },
    handleRet () {
      this.nowMobile = ''
      this.dateValue = ''
      this.pager.page = 1
      this.getList()
    },
    pageChange (page) {
      this.pager.page = page;
      this.getList();
    },

    getList () {
      let data = {}
      if (this.adminId == 1) {
        data = {
          mobile: this.nowMobile,
          startat: this.dateValue ? this.dateValue[0] / 1000 : '',
          endat: this.dateValue ? this.dateValue[1] / 1000 + 86399 : '',
          page: this.pager.page,
          pagesize: this.pager.pagesize,
          act: 'Report-Download'
        }
      } else {
        data = {
          mobile: this.nowMobile,
          startat: this.dateValue ? this.dateValue[0] / 1000 : '',
          endat: this.dateValue ? this.dateValue[1] / 1000 + 86399 : '',
          page: this.pager.page,
          pagesize: this.pager.pagesize,
          act: 'Report-Download',
          lang: 3,
        }
      }
      mainDataQuery(data).then(res => {
        // console.log(res);
        if (res.code == 200) {
          const data = JSON.parse(Base64.decode(res.data))
          if (data.data.length == 0) {
            this.user = [];
            this.pager = {
              count: 0,
              page: 1,// 默认第一页
              pagecount: 0,
              pagesize: 12//默认一页12条数据
            }
          } else {
            this.user = data.data;
            console.log(this.user);
            //分页
            var pager = data.pager;
            for (var key in pager) {
              pager[key] = parseInt(pager[key]);
            }
            this.pager = pager;
          }
        } else {
          this.$myAlert({
            type: 'fail',
            content: res.msg
          });
        }
      });
    },
    hanleLoadAll () {
      // this.enpId = 0,
      this.nowMobile = '';
      this.dateValue = '';
      this.getList();
    },
    // 时间戳转换日期
    timestampFormat (timestamp) {
      let dt = new Date(timestamp * 1000),
        y = dt.getFullYear(),
        m = dt.getMonth() + 1 >= 10 ? dt.getMonth() + 1 : ('0' + (dt.getMonth() + 1)),
        d = dt.getDate() >= 10 ? dt.getDate() : ('0' + dt.getDate());

      return y + '/' + m + '/' + d;
    },

    lookReport (id, pk, type) {
      if (pk && pk == 3) {// 母婴
        window.open('/muying/report/classify.html?id=' + id);
      } else if (pk && pk == 5) { //AI健康
        window.open('/kyzc/lookReport.html?id=' + id);
      } else if (pk && pk == 6) { //AI骨折
        window.open('/fb/lookReport.html?id=' + id);
      } else if (pk && pk == 7) { //AI骨折
        window.open('/baijin/lookReport.html?id=' + id);
      } else if (type && type == 'M22') {
        window.open('/m22/lookReport.html?id=' + id);

        return
      } else {
        let openUrl = this.$router.resolve({
          path: "/reportPrint/lookReport",
          query: {
            id: id
          }
        });

        window.open(openUrl.href);
      }

    },
    // 下载报告
    download (id, pk, type) {

      if (pk && pk == 3) {// 母婴
        window.open("/muying/report/download.html?type=download&id=" + id);
        return
      } else if (pk && pk == 5) {
        window.open('/kyzc/download.html?id=' + id);

        return
      } else if (pk && pk == 6) {
        window.open('/fb/download.html?id=' + id);

        return
      } else if (pk && pk == 7) {
        window.open('/baijin/download.html?id=' + id);

        return
      } else if (type && type == 'M22') {
        window.open('/m22/download.html?id=' + id);

        return
      } else {
        window.open("/#/reportPrint/printTemplate?type=download&id=" + id);

        return
      }

    },
    //打印报告
    print (id, pk) {
      var iframeDoc = document.getElementById('iframe'),
        url = '';
      if (pk && pk == 3) {// 母婴
        url = "/muying/report/download.html?type=print&id=" + id;
      } else {
        url = this.$router.resolve({
          path: "/reportPrint/printTemplate",
          query: {
            id: id,
            type: 'print'
          }
        }).href;
      }

      if (this.iframeUrl == url && iframeDoc.contentWindow.getData) {
        iframeDoc.contentWindow.getData();
      } else {
        this.iframeUrl = url;
      }

    }
  }
}
</script>

<style scoped>
.search>>>.el-input-group__prepend {
  width: 112px;
}

.search>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  /* padding: 10px 12px; */
  background-color: transparent;
}

.search>>>.el-select .el-input__inner {
  text-align: center;
  padding-right: 25px;
}

.search>>>.el-select .el-input__suffix {
  right: 0;
}

.search>>>.el-select .el-icon-arrow-up {
  line-height: 1;
}

.search>>>.el-range-editor .el-range-input {
  background-color: transparent;
}

.list>>>.el-table__body,
.list>>>.el-table__footer,
.list>>>.el-table__header {
  table-layout: auto;
}

.list>>>table {
  /* width: 10.92px; */
}

.list.el-table--striped>>>.el-table__body tr.el-table__row--striped td,
.list>>>thead th {
  background: #FAFBFC;
}

.list>>>.cell {
  /* padding-left: 24px; */
  padding-right: 0;
}

.list>>>thead th {
  /* display: inline-block; */
  color: #40444D;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  border-bottom: none;
  background: #F2F4FA;
}

.list>>>td {
  color: #777F8F;
  font-size: 14px;
  /* display: inline-block; */
}

.list>>>.package .cell {
  /* width: 200px; */
  /* white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis; */
}

.pagination>>>button {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.pagination>>>button .el-icon {
  font-size: 14px;
}

.pagination>>>li {
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 400;
  color: #40444D;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0 6px;
  padding: 0;
  overflow: visible;
}

.pagination>>>li:hover,
.pagination>>>li.active {
  color: #6681FA;
  font-weight: 600;
  border-color: #6681FA;
  border-left: 1px solid #6681FA;
}

.query-date>>>.el-range-separator {
  color: #DBDDE6;
}

.list>>>.el-button:active {
  background-color: transparent !important;
  color: #5B73DC;
}
</style>
<style lang="scss" scoped>
.print {
  display: flex;
  width: 100%;
  height: 100%;

  .el-button.qjc-fts-16 {
    font-size: 15px;
  }

  .left-menus {
    min-width: 170px;
    width: 170px;
    padding: 0 24px 30px;
    border-right: 1px solid #E5E8F5;
    background-color: #ffffff;
    overflow-y: auto;

    .add-action {
      display: flex;
      align-items: center;
      cursor: pointer;

      .add-action-img {
        display: flex;
        align-items: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: #6883FB;
        border-radius: 50%;

        &::after {
          content: "+";
          width: 100%;
          font-size: 20px;
          color: #fff;
          text-align: center;
          margin-bottom: 4px;
        }
      }

      .add-action-text {
        font-size: 14px;
        color: #6883FB;
        margin-left: 8px;
      }
    }

    .left-list {
      padding-top: 20px;
    }

    .left-enp-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 0;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #525866;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: #7A92FB;
      }
    }

    .left-enp-item-active {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 0;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #6883FB;
      cursor: pointer;
    }

    .enp-item-status {
      width: 20px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FF5219;
      background-color: #FDE8E2;
      margin-right: 4px;
      padding-top: 1px;
      border-radius: 50%;
      text-align: center;
    }

    .enp-item-name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .enp-item-status-active {
      width: 20px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6883FB;
      background-color: #DCE3FF;
      margin-right: 4px;
      padding-top: 1px;
      border-radius: 50%;
      text-align: center;
    }
  }

  .right-wrap {
    position: relative;
    flex: 1;
    min-width: 1050px;
    padding: 28px 24px;
    overflow: auto;

    .query-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .query-package {
        width: 226px;
      }
    }

    .c-danger {
      color: #FF4C52;
    }

    .query-date {
      display: flex;
      align-items: center;
      margin-left: 32px;

      .query-date-label {
        margin-right: 10px;
      }
    }

    .query-btn {
      width: 72px;
      height: 36px;
      line-height: 36px;
      font-size: 15px;
      padding: 0;
      margin-left: 16px;
      border: none;
      color: #fff;
    }

  }

  .search {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // margin-top: 0.32px;

    .el-input {
      width: 226px;
    }

    .el-button {
      // width: 96px;
      // height: 34px;
      // font-size: 14px;
      // font-family: PingFangSC-Medium, PingFang SC;
      // font-weight: 400;
      // color: #FFFFFF;
      // background: #6883FB;
      // border-radius: 17px;
      // border: none;
    }

    .el-button:active {
      background-color: #5B73DC;
    }

    .search-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 36px;
    }
  }

  .list {
    margin-top: 16px;

    &::before {
      display: none;
    }

    .el-button {
      font-size: 14px;
      padding: 6px 8px;
      border: none;

      +.el-button {
        margin-left: 4px;
      }
    }
  }

  .pagination {
    // margin-top: 24px;
    flex: 1;
    padding-right: 8px;
    text-align: center;
    margin-top: 8px;
    background-color: #fff;
  }
}

.el-select-dropdown__item.selected {
  color: #6681FA;
}

#iframe {
  height: 0;
}

.nextBtn {
  display: block;
  cursor: pointer;
  width: 96px;
  height: 34px;
  padding: 0 15px;
  background: rgb(102, 129, 250);
  border-radius: 2px;
  border: none;
  color: white;
  outline: none;
  font-size: 14px;
  border-radius: 17px;
  margin-left: 20px;
}

.no-print {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  font-size: 18px;

  div {
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;

    p {
      padding: 24px;
      border-bottom: 1px solid #ccc;
    }

    span {
      display: inline-block;
      padding: 12px 24px;
      cursor: pointer;
    }
  }
}

.empty-wrap {
  padding: 100px 0;
}

.empty-img {
  width: 222px;
  height: 206px;
}

.empty-text {
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #525866;
  // margin-top: 24px;
  line-height: 18px;
}

.footer-wrap {
  padding-top: 20px;
  display: flex;
  min-width: 1000px;
  max-width: 100%;
  width: 100%;
  height: 80px;
  background-color: #fff;
}

.btn-text {
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
}
</style>
