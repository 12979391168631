<template>
    <div class="alert" v-show="hidden">
        <div class="alertBox">
            <div class="alertTit">溫馨提示 <span @click="close">&#10005;</span></div>
            <div class="tipStatus">{{tipStatus}}</div>
            <div class="tipText">{{tipText}}</div>
            <div class="alertConfirm"  @click="confirm">確定</div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "alertb",
        props:{
            hidden:{
                type:Boolean,
                default:false
            },
            tipStatus:{
                type:String,
            },
            tipText:{
                type:String,
            },
        },
        data(){
            return {
                alertHidden:false,
            }
        },
        mounted() {
        },
        methods:{
            close(){
                if(this.hidden){
                    this.$emit('on-close')
                }
            },
            confirm(){
                if(this.hidden){
                    this.$emit('on-confirm')
                }
            },
        }
    }
</script>

<style scoped>
    .alert{
        position: fixed;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display:flex;
        align-items:center;
        justify-content:center;
        z-index: 999;
    }
    .alertBox{
        width: 2.86rem;
        height: 2.2rem;
        background: #FFFFFF;
    }
    .alertTit{
        padding: 0 0.12rem;
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.12rem;
        color: white;
        font-weight: 400;
        font-family: PingFangSC;
        background: #6883FB;
    }
    .alertTit span{
        cursor: pointer;
        float: right;
        font-size: 0.16rem;
    }
    .tipStatus{
        margin-top: 0.3rem;
        /*line-height: 0.3rem;*/
        font-weight: 500;
        font-size: 0.14rem;
        text-align: center;
        color: #40444D;
    }
    .tipText{
        padding: 0 0.2rem;
        margin: 0.15rem 0;
        line-height: 0.22rem;
        font-weight: 400;
        font-size: 0.12rem;
        text-align: center;
        color: #777F90;
    }
    .alertConfirm{
        cursor: pointer;
        margin: 0 auto;
        width: 1.5rem;
        height: 0.4rem;
        line-height: .4rem;
        text-align: center;
        font-size: 0.14rem;
        color: white;
        background: #6883FB;
        border-radius: .2rem;
    }
</style>